import setImgFallbackMixin from '@/common/set-img-fallback-mixin';

const setStylesMixin = {
	methods : {
		setInitStyles() {
			let isCatalog  = this.config.template === 'catalog_v1' || this.config.template === 'catalog_v2';
			const styles   = document.getElementById('injected-styles');
			const textnode = document.createTextNode(`
body {font-family: '${this.config.font_type_name !== null ? this.config.font_type_name : 'Roboto'}', sans-serif !important;}
.Cookie--mytheme {background: ${this.cssData.footer_bg_color}!important;}
.flip-clock {font-family: '${this.jsonConfig.countdown !== undefined ? this.jsonConfig.countdown.font_name : 'Arial'}', sans-serif !important; color: ${this.jsonConfig.countdown !== undefined ? this.jsonConfig.countdown.text_color : '#000000'}!important;}
.flip-card__top,.flip-card__back::before {background: ${this.jsonConfig.countdown !== undefined ? this.jsonConfig.countdown.card_top_color : '#000000'}!important;}
.flip-card__bottom,.flip-card__back-bottom {background: ${this.jsonConfig.countdown !== undefined ? this.jsonConfig.countdown.card_bottom_color : '#000000'}!important;}
body {color: ${this.cssData.body_text_color} !important;}
.main .position .right-side {background:${this.cssData.form_bg_color} !important;color:${this.cssData.form_text_color} !important;height:${this.formData !== null && this.formData.form_height !== null ? this.formData.form_height : '625px'} !important;}
nav.navbar.navbar-light.bg-light{background:${this.cssData.nav_bg_color} !important;}
.footer {background:${this.cssData.footer_bg_color} !important;}
.main {background:${this.cssData.body_bg_color} !important;}
a{color:${this.cssData.link_text_color}!important;}
a:hover{color:${this.cssData.link_text_color}!important;}
.hooper-indicator:hover,.hooper-indicator.is-active {background-color:${this.cssData.link_text_color}!important;}
.btn.btn-primary{border-radius: ${this.cssData.buttons_border_radius !== null ? this.cssData.buttons_border_radius : '0rem'} !important;color: ${this.cssData.button_text_color} !important;background:${this.cssData.button_bg_color}!important;border-color:${this.cssData.button_bg_color}!important;}
.btn.btn-primary:hover{background:${this.cssData.button_border_color}!important;border-color:${this.cssData.button_border_color}!important;}
.slideshow-btn{border-radius: ${this.cssData.buttons_border_radius !== null ? this.cssData.buttons_border_radius : '0rem'}!important;}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
	background-color: ${this.cssData.radio_button_bg_color}!important;
}
.footer .Cookie--mytheme a {color: ${this.cssData.footer_link_text_color !== null ? this.cssData.footer_link_text_color : this.cssData.link_text_color} !important;}
.footer a {color:${this.cssData.footer_link_text_color !== null ? this.cssData.footer_link_text_color : this.cssData.link_text_color} !important;}

.logo-address{ ${this.config.addressv1 !== undefined ? this.config.addressv1.logos.css : ''}}
.addressv2 a,.addressv1 a{color: ${this.cssData.body_text_color} !important;}
.custom-control-input:checked ~ .custom-control-label::before {
    border-color: ${this.cssData.radio_button_border_color}!important;
    background-color: ${this.cssData.radio_button_bg_color}!important;
}
.custom-control-input:checked ~ .custom-control-label::before {
    background-color: ${this.cssData.radio_button_bg_color}!important;
}
.hooper svg.icon.icon-arrowLeft,.hooper svg.icon.icon-arrowRight {fill: ${this.jsonConfig.slideshow !== undefined && this.jsonConfig.slideshow.navigation_arrow_color !== undefined ? this.jsonConfig.slideshow.navigation_arrow_color : '#000'}!important}
.thank-you,.success-message,.success-message2{color:${this.cssData.form_success_text_color}!important;}
.address p strong{color:${this.cssData.address_strong_color}!important;}
.right-side svg path{fill:${this.cssData.form_success_text_color} !important;}
.form-title,.form-sub-title,.form-text-color,.custom-control-label{color:${this.cssData.form_text_color} !important;}

.scroller .fa-chevron-down{color: ${this.cssData.scroller_color} !important;}
@media only screen and (min-width: 1024px) {
	.main .container-fluid.container-top {
		background: url(${(this.config.image_desktop_responsive === 0 ? (!isCatalog ? setImgFallbackMixin.methods.setImgFallback(this.config.bg_desktop) : 'unset') : 'unset')}) center top no-repeat ${this.cssData.body_bg_color} !important;
	}
	.container-fluid.container-top.bottom-form {
		height : 100% !important;
	}
	.main .container-fluid.container-top {
		height : ${this.config.form === null ? '400px' : '100%'} !important;
	}
}

.container_after_form{background-color: ${this.jsonConfig.container_after_form !== undefined ? this.jsonConfig.container_after_form.bg_color : '#000000'}!important;}
.container_after_form h4{color: ${this.jsonConfig.container_after_form !== undefined ? this.jsonConfig.container_after_form.text_color : '#000000'}!important;}
.container_after_form p{color: ${this.jsonConfig.container_after_form !== undefined ? this.jsonConfig.container_after_form.text_color : '#000000'}!important;}
.addressv2 .schedule i{color: ${this.jsonConfig.addressv2 !== undefined ? this.cssData.body_text_color : '#000000'}!important;}
.cta .cta_text_1{color: ${this.jsonConfig.cta !== undefined ? this.jsonConfig.cta.text_color_1 : '#000000'}!important;}
.cta .cta_text_2{color: ${this.jsonConfig.cta !== undefined ? this.jsonConfig.cta.text_color_2 : '#000000'}!important;}
.cta-bg{background-color: ${this.jsonConfig.cta !== undefined ? this.jsonConfig.cta.bg_color : '#000000'}!important;}
.img-responsive {color: ${this.jsonConfig.youtube !== undefined ? this.jsonConfig.youtube.image.button_color : '#000000'}!important;}

.catalog-feed .card .card-price .price, .catalog-feed .card .card-price-promo .price, .catalog-container-v2 .price,
.catalog-container-v2 .info-top .price-zone .price {
	color: ${this.cssData.price_text_inner_color} !important;
	background-color: ${this.cssData.price_color} !important;
	border-radius: ${this.cssData.price_border_radius} !important;
}
.catalog-container-v1 .catalog .price {
	color: ${this.cssData.price_color} !important;
}

.container-navigate-out{
	color: ${this.jsonConfig.navigate_out_zone !== undefined && this.jsonConfig.navigate_out_zone !== null ? this.jsonConfig.navigate_out_zone.settings.text_color : '#000000'} !important;
	background-color: ${this.jsonConfig.navigate_out_zone !== undefined && this.jsonConfig.navigate_out_zone !== null ? this.jsonConfig.navigate_out_zone.settings.bg_color : 'white'} !important;
}

.container-navigate-out a, .container-navigate-out a:hover{
	color: ${this.cssData.button_bg_color} !important;
	border: 3px solid ${this.cssData.button_bg_color} !important;
	border-radius: ${this.cssData.buttons_border_radius !== null ? this.cssData.buttons_border_radius : '0rem'} !important;
}
.container-navigate-out a:hover{
	opacity: 0.8!important;
}

.catalog-feed .card .card-price p, .catalog-feed .card .card-price-promo p.after, .catalog-container-v2 .info-top .price-zone .after {
	color: ${this.cssData.price_text_top_color} !important;
}

input.form-control,select.form-control{
	border-radius: ${this.cssData.form_border_radius !== null ? this.cssData.form_border_radius : (this.config.template === 'base_2022' ? '0.25rem 0.25rem 0 0' : '0.25rem')} !important;
}

textarea.form-control{
	border-radius: ${this.cssData.form_border_radius !== null ? 'calc(' + this.cssData.form_border_radius + '/4)' : (this.config.template === 'base_2022' ? '0.25rem 0.25rem 0 0' : '0.25rem')} !important;
}

${this.cssData.injected_css}

.slideshow-top .hooper {
    height: ${this.jsonConfig.slideshow_top !== undefined ? this.jsonConfig.slideshow_top.height_desktop : 0}!important;
}

@media only screen and (min-width: 768px)  and (max-width: 1024px){
	${this.cssData.injected_css_ipad}
	.slideshow-top .hooper {
		height: ${this.jsonConfig.slideshow_top !== undefined ? this.jsonConfig.slideshow_top.height_ipad : 0}!important;
	}
}

@media only screen and (max-width: 1023px) {
	.image-top{${this.config.nav_active === 1 && this.config.responsive_image_mobile === 1 ? 'margin-top:30px!important;' : 'margin-top:0px!important;'}}
	.cta-bg{${this.config.responsive_image_mobile === 1 ? 'margin-top:0px!important;' : ''}}
}
@media only screen and (max-width: 700px) {
	${this.cssData.injected_css_mobile}
	.slideshow-top .hooper {
		height: ${this.jsonConfig.slideshow_top !== undefined ? this.jsonConfig.slideshow_top.height_mobile : 0}!important;
	}
}

@media only screen and (max-width: 768px) {
    .main .position .right-side {
        height: 100% !important;
    }
}

@media only screen and (min-width: 769px) {
    .whatsapp-float { display: ${this.config.whatsapp_hide_desktop === 1 ? 'none' : 'block'} !important;}
}

${this.config.css_fast_fixes}

`);
			styles.appendChild(textnode);
		},
	},
};
export default setStylesMixin;
