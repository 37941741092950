const setImgFallbackMixin = {
	methods : {
		setImgFallback(img) {
			const { userAgent } = navigator;

			if (userAgent.includes('Firefox/')) {
				return img;
			} else if (userAgent.includes('Edg/')) {
				return img;
			} else if (userAgent.includes('Chrome/')) {
				return img;
			} else if (userAgent.includes('Safari/')) {
				// 404 on PNG
				return img !== null ? img.replace('/conversions', '').replace('.webp', '.jpg') : img;
			}

			return img;
		},
		setImgFallbackPNG(img) {
			const { userAgent } = navigator;

			if (userAgent.includes('Firefox/')) {
				return img;
			} else if (userAgent.includes('Edg/')) {
				return img;
			} else if (userAgent.includes('Chrome/')) {
				return img;
			} else if (userAgent.includes('Safari/')) {
				// 404 on PNG
				return img !== null ? img.replace('/conversions', '').replace('.webp', '.png') : img;
			}

			return img;
		}
	}
};

export default setImgFallbackMixin;
